<template>
  <div>
    <KTCodePreview v-bind:title="'In đơn hàng'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="printData"
          style="width: max-content"
        >
          <i
            style="font-size: 1rem"
            class="fas fa-print"
          ></i>
          In đơn hàng
        </b-button>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <hr
          class="hr-text"
          data-content="Khổ giấy A4- A5"
          style="font-weight: 600"
        />
        <div id="A4">
          <PrintOrderA4 :payload="dataset" />
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import PrintOrderA4 from '../../components/order/PrintOrderA4.vue';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import _ from 'lodash';
import { ITEM_TYPE, PRODUCT_TYPE } from '@/utils/enum.js';
import {
  makeToastFaile,
  convertPrice,
  calculateDiscountAmount,
  cloneDeep,
  catchNum,
} from '@/utils/common.js';
import { mapGetters } from 'vuex';
import { EventBus } from '@/core/services/event-bus';

export default {
  data() {
    return {
      barcode: 'barcode',
      ITEM_TYPE: ITEM_TYPE,
      PRODUCT_TYPE: PRODUCT_TYPE,
      dataset: {
        id: '',
        storeId: '',
        storeAddress: '',
        storeName: '',
        status: '',
        customerNote: '',
        checkDate: '',
        checkTime: '',
        codeShip: '',
        discountAmount: '',
        transferAmount: '',
        depositAmount: '',
        paymentDate: '',
        customerShipFee: '',
        shipFee: '',
        orderSourceId: '',
        saleNote: '',
        orderItems: [],
        createdAt: '',
        createdBy: '',
        customerId: '',
        customerName: '',
        customerMobile: '',
        customerAddress: '',
        cashAccountName: '',
        transferAccountName: '',
        saleId: '',
        creditAmount: '',
        installedMoneyAmount: 0,
        totalAmount: 0,
      },
      currentDay: moment(new Date()).format('DD/MM/YYYY'),
      employeeName: 'Trung',
      orderId: null,
    };
  },
  async created() {
    const orderId = this.$route.query.id || null;
    if (!orderId) {
      makeToastFaile('Yêu cầu mã id đơn hàng');
    }
    await this.getOrderById(orderId);
    this.orderId = orderId;
  },
  methods: {
    catchNum,
    convertPrice,
    htmlIsShow(item, action) {
      let check = false;
      switch (action) {
        case 'isCombo': {
          check =
            item.productType === PRODUCT_TYPE.PRODUCT_COMBO ? true : false;
          break;
        }
        case 'haveImei': {
          check = item.imeiCode && item.imeiCode.trim().length > 0;
          break;
        }
        case 'isItemGift': {
          check = item.orderItemType === ITEM_TYPE.GIFT ? true : false;
          break;
        }
      }
      return check;
    },
    sumAmountItem(item) {
      const quantity = Number(item.quantity) || 0;
      const productPrice = Number(item.productPrice) || 0;
      const totalAmountItem = quantity * productPrice;
      const discountProducts = this.discountByMany(item, quantity);

      return totalAmountItem - discountProducts;
    },
    discountByMany(item, quantity) {
      const quantityItem = quantity
        ? quantity
        : item.quantity
        ? Number(item.quantity)
        : 0;
      const discountOfOneProduct = this.discountByOne(item);
      return quantityItem * discountOfOneProduct;
    },
    discountByOne(item) {
      const discountAmount = Number(item.discountAmount) || 0;
      const discountType = Number(item.discountType) || 1;
      const quantity = Number(item.quantity) || 0;
      const productPrice = Number(item.productPrice) || 0;

      const discountAProduct = calculateDiscountAmount(
        discountAmount,
        discountType,
        productPrice,
        quantity,
      );
      return discountAProduct;
    },
    printData() {
      let prtHtml = null;
      prtHtml = document.getElementById('printMe').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          <style>
          * {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
            color-adjust: exact !important;                 /* Firefox 48 – 96 */
            print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
          }
          @media print {
            html,
            body {
              font-family: Time New Roman, sans-serif;
            }
            .container {
              -webkit-print-color-adjust: exact;
              --primary-color: #000000;
              position: relative;
              height: 100%;
            }

            .container::before {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              opacity: 1;
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 50px;
              width: 100%;
              height: 96%;
              opacity: 0.2;
              background-image: url(/media/logos/ddv_1.png);
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
            }
            @page {
              size:A4;
              margin-left: 0px;
              margin-right: 0px;
              margin-top: 0px;
              margin-bottom: 0px;
              margin: 0;
              -webkit-print-color-adjust: exact;
            }
            
            footer {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 40px;
              background-color: #000;
              color: #fff;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 40px;
            }

            footer p {
              margin-bottom: 0;
              color: #fff !important;
            }
          }
          /* ============ common ============ */
          .container {
            -webkit-print-color-adjust: exact;
            --primary-color: #000000;
            position: relative;
            height: 100%;
          }

          .container::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50px;
            width: 100%;
            height: 96%;
            opacity: 0.2;
            background-image: url(/media/logos/ddv_1.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
          .text-center {
            text-align: center;
          }

          .text-right {
            text-align: right;
          }

          .font-size-large {
            font-size: 26px;
            color: var(--primary-color);
          }

          .font-size-print {
            color: var(--primary-color);
            font-size: 20px;
          }

          .text-bold-500 {
            font-weight: 500;
          }

          .text-bold-400 {
            font-weight: 400;
          }

          /* ============ heading ============ */
          .header {
            height: 140px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .header .logo {
            flex: 1;
          }

          .header .order-info {
            flex: 1;
            display: inline-block;
            text-align: center;
          }

          .header .order-info .order-detail {
            display: flex;
            justify-content: center;
            gap: 60px;
          }

          .logo img {
            object-fit: contain;
            max-width: 100%;
          }

          /* ============ order info ============ */
          .order-info {
            display: flex;
            justify-content: center;
          }

          /* ============ information ============ */
          .information {
            height: fit-content;
            display: flex;
            gap: 20px;
          }

          .information .company {
            flex-basis: calc(50% - 20px);
            border: 0.5px solid black;
            border-radius: 4px;
            padding: 10px;
          }

          .information .customer {
            flex-basis: 50%;
            border: 0.5px solid black;
            border-radius: 4px;
            padding: 10px;
          }

          .information p {
            margin-bottom: 0px;
            line-height: 1.4;
          }

          .products {
            margin-top: 20px;
          }

          table {
            width: 100%;
          }

          table,
          th,
          td {
            border: 1px solid black;
            padding: 8px;
          }

          .signing {
            display: flex;
            height: 100px;
            margin-top: 20px;
          }

          .sign-customer,
          .sign-sale,
          .sign-accountant {
            flex: 30%;
            text-align: center;
          }

          .sign-customer::after,
          .sign-sale::after,
          .sign-accountant::after {
            content: '(Ký, họ tên)';
            display: inherit;
            font-style: italic;
            font-size: 16px;
          }
          /* Ghi chú */
          .note {
            margin-top: 20px;
          }
          </style>
        </head>
        <body>`);
      WinPrint.document.write(`${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, 500);
    },
    getDistrictById: async function (cityId, districtId) {
      await ApiService.get(`city/${cityId}/district`).then((response) => {
        const { data } = response.data;
        const districtKey = _.keyBy(data, 'id');
        const district = districtKey[districtId];
        this.dataset.customerDistrict = district.name;
      });
    },
    getWardById: async function (districtId, wardId) {
      await ApiService.get(`city/${districtId}/ward`).then((response) => {
        const { data } = response.data;
        const wardKey = _.keyBy(data, 'id');
        const ward = wardKey[wardId];
        this.dataset.customerWard = ward.name;
      });
    },
    getOrderById: async function (orderId) {
      await ApiService.query(`orders/${orderId}`).then(async (response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        this.dataset.orderItems = [];
        if (status) {
          data.orderItems = this.getOrderItems(data);
          const sumPayment =
            catchNum(data.installedMoneyAmount) +
            catchNum(data.creditAmount) +
            catchNum(data.transferAmount) +
            catchNum(data.depositAmount);
          const totalAmountToPay = data.totalAmount - sumPayment;
          let totalUnitPrice = 0;
          let totalQuantity = 0;
          let totalDiscountAmount = 0;
          data.orderItems.forEach((product) => {
            totalUnitPrice += product.productPrice;
            totalQuantity += product.quantity;
            totalDiscountAmount += product.discountAmount;
          });
          data.sumPayment = sumPayment;
          data.totalAmountToPay = totalAmountToPay;
          data.totalUnitPrice = totalUnitPrice;
          data.totalQuantity = totalQuantity;
          data.totalDiscountAmount = totalDiscountAmount;

          this.dataset = data;
        } else {
          makeToastFaile(message);
        }
        await Promise.all([
          this.getCustomerInfoById(data.customerId),
          this.getStoreById(data.storeId),
        ]);
        EventBus.$emit('order-fetched', this.dataset);
      });
    },
    getOrderItems(data) {
      const terms = cloneDeep(data.orderItems);
      const mainItems = terms.filter(
        (item) => item.orderItemType === ITEM_TYPE.MAIN,
      );
      const childItems = terms.filter(
        (item) => item.orderItemType !== ITEM_TYPE.MAIN,
      );
      let orderItems = [];
      mainItems.map((main) => {
        orderItems.push(main);
        const filteredItems = childItems.filter(
          (child) => child.belongOrderDetailId === main.id,
        );
        orderItems = orderItems.concat(filteredItems);
      });
      return orderItems;
    },
    getCustomerInfoById: async function (customerId) {
      await ApiService.get(`customer/${customerId}`).then((response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        if (status) {
          const customerName = this.dataset.customerName || data.fullName;
          const customerAddress = this.dataset.customerAddress || data.address;

          this.dataset.customerName = customerName;
          this.dataset.customerAddress = customerAddress;

          if (data.city && data.disctrict) {
            this.getDistrictById(data.city, data.district);
          }

          if (data.district && data.ward) {
            this.getWardById(data.district, data.ward);
          }
        } else {
          makeToastFaile(message);
        }
      });
    },
    getStoreById: function (storeId) {
      if (!storeId) {
        return;
      }
      ApiService.query(`stores/${storeId}`).then((response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        if (status) {
          this.dataset.storeAddress = data.address;
        } else {
          makeToastFaile(message);
        }
      });
    },
    getAddressCustomer(dataset) {
      const data = {
        customerAddress: dataset.customerAddress,
        customerWard: dataset.customerWard,
        customerDistrict: dataset.customerDistrict,
        customerCity: dataset.customerCity,
      };

      const pickedByData = _.pickBy(data);
      if (_.isEmpty(pickedByData)) return '';
      return Object.values(pickedByData).join(',');
    },
  },
  components: {
    KTCodePreview,
    PrintOrderA4,
  },
  computed: {
    computedData() {
      return this.dataset.slice(); // create a copy of the array to trigger reactivity
    },
    ...mapGetters(['layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return this.layoutConfig('loader.logo');
    },
    sumDiscountHeader() {
      const order = this.dataset;

      const discountAmount = order.discountAmount;
      const discountType = order.discountType || 1;

      const totalAmount = this.sumTotalAmount;

      const discountTotalAmount = calculateDiscountAmount(
        discountAmount,
        discountType,
        totalAmount,
      );

      return discountTotalAmount;
    },
    sumShipFee() {
      const order = this.dataset;
      const customerShipFee = Number(order.customerShipFee) || 0;
      const shipFee = Number(order.shipFee) || 0;
      return customerShipFee + shipFee;
    },
    sumDiscountItemsAmount() {
      const orderItems = this.dataset.orderItems || [];

      const distcountAmountItems = orderItems.reduce((result, current) => {
        const discount = this.discountByMany(current);
        return result + discount;
      }, 0);
      return distcountAmountItems;
    },
    sumDiscountAmount() {
      const distcountAmountItems = this.sumDiscountItemsAmount;

      const discountTotalAmount = this.sumDiscountHeader;

      return discountTotalAmount + distcountAmountItems;
    },
    sumTotalFinal() {
      const sumDiscountHeader = this.sumDiscountHeader;
      const totalAmount = this.sumTotalAmount;
      const shipFee = this.sumShipFee;
      return totalAmount + shipFee - sumDiscountHeader;
    },
    sumTotalAmount() {
      const orderItems = this.dataset.orderItems || [];

      const sumOrderItems = orderItems.reduce((result, item) => {
        if (item.orderItemType !== ITEM_TYPE.GIFT) {
          const sumAmountItem = this.sumAmountItem(item);
          result += sumAmountItem;
        }
        return result;
      }, 0);
      return sumOrderItems;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Đơn hàng', route: '/orders' },
      { title: 'In đơn hàng' },
    ]);
  },
};
</script>

<style scoped>
.header-print {
  text-align: center;
  width: 100%;
}
.font-size-print {
  font-size: 13px;
}
.font-size-name {
  font-size: 13px;
}
.container-header {
  display: flex;
}
.container-header > div:first-child {
  margin-right: auto;
  visibility: hidden;
}
.container-header > div:last-child {
  margin-left: auto;
}
</style>
